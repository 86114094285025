<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>เว็บไซต์</strong>
      <button class="btn btn-sm btn-link" @click="addWebsite">เพิ่มเว็บไซต์</button>
    </div>
    <div class="card">
      <table class="table table-group table-sm table-hover mb-0">
        <thead>
          <tr>
            <th width="70%">รายการ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td style="vertical-align: middle;">{{ item.item_name }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="setMarkets(item)">ตั้งค่าหวย</button>
              <button class="btn btn-sm btn-link mx-2" @click="editWebsite(item)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="items.length == 0">
            <td colspan="2" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <WebsiteModal :data="data" :is-show="isShowModal" @close="modalClosed" />
    <WebsiteMarketModal :data="data" :groups="Groups" :markets="Markets" :is-show="isShowMarketModal" @close="modalClosed" />
  </div>
</template>
<script>
import TransactionService from '@/services/TransactionService'
import WebsiteModal from './components/WebsiteModal'
import WebsiteMarketModal from './components/WebsiteMarketModal'

const defaultData = {
  _id: null,
  item_type: 'Website',
  item_name: ''
}

export default {
  name: 'AdminSettingWebsite',
  components: {
    WebsiteModal,
    WebsiteMarketModal
  },
  data() {
    return {
      isShowModal: false,
      isShowMarketModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
      Websites: [],
      Groups: [],
      Markets: []
    }
  },
  computed: {
    items() {
      return this.Websites
    }
  },
  methods: {
    loadWebsite() {
      TransactionService.gets('Website').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Websites = response.data
        }
      })
    },
    loadGroup() {
      TransactionService.gets('MarketGroup').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Groups = response.data
        }
      })
    },
    loadMarket() {
      TransactionService.gets('Market').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Markets = response.data
        }
      })
    },
    addWebsite() {
      this.data = JSON.parse(JSON.stringify(defaultData))
      this.isShowModal = true
    },
    editWebsite(item) {
      console.log(item)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: item._id,
        item_type: item.item_type,
        item_name: item.item_name
      }
      this.isShowModal = true
    },
    setMarkets(item) {
      console.log(item)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: item._id,
        item_type: item.item_type,
        item_name: item.item_name,
        options: item.options
      }
      this.isShowMarketModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      this.isShowMarketModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadWebsite()
      }
    },
  },
  mounted() {
    this.loadGroup()
    this.loadMarket()
    this.loadWebsite()
  }
}
</script>
