<template>
  <b-modal
    id="website-madal"
    ref="website-madal"
    hide-footer
    title="ตั้งค่าหวย"
    @show="resetModal"
    @hidden="resetModal"
    size="lg"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <div class="mb-3 text-center">
        <b-button-group size="sm">
          <b-button variant="success" @click="selectAll">เลือกทั้งหมด</b-button>
          <b-button variant="warning" @click="unselectAll">ยกเลิกทั้งหมด</b-button>
          <b-button v-for="group in groups" :key="group._id" variant="info" @click="selectGroup(group._id)">{{group.item_name}}</b-button>
        </b-button-group>
      </div>

      <b-form-group v-for="(group, groupKey) in groupBy" :key="groupKey" :label="group.item_name" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="input.options.marketIds"
          :options="group.items"
          :aria-describedby="ariaDescribedby"
        ></b-form-checkbox-group>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import TransactionService from '@/services/TransactionService.js'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
  name: 'WebsiteModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    groups: {
      type: Array
    },
    markets: {
      type: Array
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    groupBy() {
      const groupItems = _.groupBy(this.markets, 'owners.mGroupId')
      return this.groups.map((group)=>{
        const items = groupItems[group._id] || []
        return {
          ...group,
          items: items.map((item)=>{
            return {
              text: item.item_name,
              value: item._id
            }
          }) || []
        }
      })
      .filter((group)=>{
        return group?.items
      })
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['website-madal'].show()
    },
    hideModal() {
      this.$refs['website-madal'].hide()
    },
    handleSubmit() {
      console.log(JSON.stringify(this.input))
      if(this.input?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    selectAll() {
      this.input.options.marketIds = this.markets.map((market)=>{
        return market._id
      })
    },
    unselectAll() {
      this.input.options.marketIds = []
    },
    selectGroup(groupId) {
      this.input.options.marketIds = this.markets.filter((market)=>{
        return market.owners.mGroupId === groupId
      })
      .map((market)=>{
        return market._id
      })
    },
    save() {
      TransactionService.save(this.input)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    update() {
      TransactionService.update(this.input._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.status === 'success') {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }
        })
        .catch((e)=>{
          Swal.fire({
            title: e?.name || 'ผิดพลาด!',
            text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#website-madal {
  input[type=text] {
    color: blue;
  }
}
</style>
